import React, { useEffect } from 'react';

function CloudButtons(props) {
  const [clouds, setClouds] = React.useState('clouds-00');
  const inactive = props.inactive;
  let existingData = props.data.clouds !== '' ? true : false;

  // console.log(props.inactive);

  // const guessClouds = e => {
  //   console.log('Clicked');
  //   console.log(e.target.id);
  //   setClouds(e.target.id);
  // };

  const guessClouds = id => {
    setClouds(id);
  };

  useEffect(() => {
    if (!inactive) {
      setClouds(props.data.clouds || 'clouds-00');
    }
  }, [props.data.clouds]);

  const cloudOptions = [
    { id: 'clouds-00', symbol: 'sunny' },
    { id: 'clouds-01', symbol: 'partly_cloudy_day' },
    { id: 'clouds-02', symbol: 'cloud' },
  ];

  let cloudButtons = cloudOptions.map(x => (
    <button
      key={x.id}
      id={x.id}
      className={
        !existingData
          ? clouds === x.id
            ? 'option-chosen'
            : ''
          : props.data.clouds === x.id
          ? 'option-chosen'
          : ''
      }
      onClick={!inactive ? () => guessClouds(x.id) : undefined}
    >
      <span className='material-symbols-outlined'>{x.symbol}</span>
    </button>
  ));

  useEffect(() => {
    // console.log(clouds);
  }, [clouds]);

  useEffect(() => {
    if (!inactive) {
      let newData = { ...props.data };
      newData.clouds = clouds;
      props.setData(newData);
    }
  }, [clouds]);

  return (
    <div className='cloudButtons guessing-buttons toggle-buttons'>
      {cloudButtons}
    </div>
  );
}

export default CloudButtons;
