function WinningMessage(props) {
  if (props.winner === 'playerWon') {
    return (
      <div className='winningMessage'>
        <p>You won, you glorious bastard!</p>
      </div>
    );
  } else if (props.winner === 'computerWon') {
    return (
      <div className='winningMessage'>
        <p>The computer won, all hail machines!</p>
      </div>
    );
  }
}

export default WinningMessage;
