import capitalList from './capitalList';

function randomCapital(actualData, setActualData) {
  let i = Math.floor(Math.random() * capitalList.length);
  let randomCapital = capitalList[i];
  let newActualData = { ...actualData };
  newActualData.city = randomCapital.city;
  newActualData.country = randomCapital.country;
  newActualData.lat = randomCapital.lat;
  newActualData.lon = randomCapital.lon;
  setActualData(newActualData);
}

export default randomCapital;
