import React, { useEffect } from 'react';

function PrecipitationButtons(props) {
  const [precipitation, setPrecipitation] = React.useState('');
  const inactive = props.inactive;

  const precipitationOptions = [
    { id: 'rain', symbol: 'rainy' },
    { id: 'snow', symbol: 'cloudy_snowing' },
  ];

  useEffect(() => {
    if (!inactive) {
      setPrecipitation(
        props.data.rain !== ''
          ? 'rain'
          : props.data.snow !== ''
          ? 'snow'
          : '' || ''
      );
    }
  }, [props.data.rain || props.data.snow]);

  let precipitationButtons = precipitationOptions.map(x => (
    <button
      key={x.id}
      id={x.id}
      className={precipitation === x.id ? 'option-chosen' : ''}
      onClick={inactive === false ? () => guessPrecipitation(x.id) : undefined}
    >
      <span className='material-symbols-outlined'>{x.symbol}</span>
    </button>
  ));

  const guessPrecipitation = id => {
    let newPrecipitation;
    if (precipitation === id) {
      newPrecipitation = '';
    } else newPrecipitation = id;
    setPrecipitation(newPrecipitation);
  };

  useEffect(() => {
    let newData = { ...props.data };
    if (precipitation === 'rain') {
      newData.rain = true;
      newData.snow = '';
    } else if (precipitation === 'snow') {
      newData.snow = true;
      newData.rain = '';
    } else if (precipitation === '') {
      newData.rain = '';
      newData.snow = '';
    }

    props.setData(newData);
  }, [precipitation]);

  return (
    <div className='cloudButtons guessing-buttons toggle-buttons'>
      {precipitationButtons}
    </div>
  );
}

export default PrecipitationButtons;
