import React, { useEffect, useState } from 'react';
import GameField from './BingoGameField';
import DrawnNumbers from './DrawnNumbers';
import checkPattern from './checkPattern';
import Intro from './Intro';
import WinningMessage from './WinningMessage';
import ChooseDifficulty from './ChooseDifficulty';
import NewNumbersButton from './NewNumbersButton';

import './Bingo.css';

function Bingo() {
  const [field, setField] = React.useState([]);
  const [difficulty, setDifficulty] = React.useState('easy');
  const [drawn, setDrawn] = React.useState([]);
  const [match, setMatch] = React.useState(Array(25).fill(''));
  const [win, setWin] = React.useState(false);
  const [points, setPoints] = React.useState(0);

  const rndNum = function () {
    let scope;
    difficulty === 'hard'
      ? (scope = 50)
      : difficulty === 'medium'
      ? (scope = 25)
      : (scope = 10);
    let num = Math.floor(Math.random() * scope + 1);
    return num;
  };

  const buildGameField = function () {
    let newField = [];
    for (let i = 0; i < 25; i++) {
      let num = rndNum();
      newField.push(num);
    }
    setField(newField);
  };

  // function NewNumbersButton(props) {
  //   const clickHandler = function () {
  //     props.drawNumbers();
  //     let newPoints = props.points - 10;
  //     props.setPoints(newPoints);
  //   };
  //   return (
  //     <button className='button btn-subtle' onClick={clickHandler}>
  //       {/* <i className='material-icons' style={{ fontSize: '1rem' }}>
  //         autorenew
  //       </i> */}
  //       Draw
  //     </button>
  //   );
  // }

  const drawNumbers = function () {
    let newDrawn = [];
    for (let i = 0; i < 5; i++) {
      let num = rndNum();
      while (newDrawn.includes(num)) {
        num = rndNum();
      }
      newDrawn.push(num);
    }
    setDrawn(newDrawn);
  };

  const drawNewNumbers = function () {
    drawNumbers();
    let newPoints = points - 10;
    setPoints(newPoints);
  };

  const checkMatch = function (e) {
    let i = e.target.id;
    let num = field[i];
    if (drawn.includes(num)) {
      let newMatch = [...match];
      newMatch[i] = 'X';
      setMatch(newMatch);
      let newField = [...field];
      newField[i] = '_' + newField[i];
      setField(newField);
      let newPoints = points + 5;
      setPoints(newPoints);
    } else {
      let newPoints = points - 3;
      setPoints(newPoints);
    }
  };

  const resetGame = function () {
    setWin(false);
    setPoints(0);
    setMatch(Array(25).fill(''));
    setField([]);
    buildGameField();
    setDrawn([]);
    drawNumbers();
  };

  useEffect(() => {
    checkPattern(setWin, match);
  }, [match]);

  useEffect(() => {
    drawNumbers();
  }, []);

  useEffect(() => {
    resetGame();
  }, [difficulty]);

  return (
    <main className='bingo'>
      <Intro />
      <div className='gameArea'>
        <div className='gameHeader'>
          <div className='score'>Score: {points}</div>
          <ChooseDifficulty
            difficulty={difficulty}
            setDifficulty={setDifficulty}
          />
          <button
            className='button btn-subtle'
            onClick={() => drawNewNumbers()}
          >
            Draw
          </button>
        </div>
        <DrawnNumbers drawn={drawn} />

        <GameField field={field} clickHandler={checkMatch}></GameField>

        <button
          className='button btn-highlight reset-btn'
          onClick={() => resetGame()}
        >
          Reset
        </button>
        {win && <WinningMessage clickHandler={resetGame} />}
      </div>
    </main>
  );
}

export default Bingo;
