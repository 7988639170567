function Intro() {
  return (
    <section className='intro'>
      <h2>Hello World!</h2>
      <p>
        I'm Nadja, digital marketing veteran (and currently stay-at-home mom) by
        day, and avid coder by night. This is my own little coding playground
        where I develop and deploy different projects for the world to see.
        Well, to be realistic: at this point mostly to show off to my friends
        and family.
      </p>
      <p>
        Although this might look like a website, it is actually not. You're
        looking at an app built with React, which I'm still learning, but
        already loving. It is an ongoing work-in-progress and will probably
        never be perfect, but then the only way to learn is through mistakes.
      </p>
      <p>
        Do you want to get to know me or have a chat? Awesome - please feel free
        to reach out via the lovely red button in the header. I'm looking
        forward to hearing from you!
      </p>
    </section>
  );
}

export default Intro;
