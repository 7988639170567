import Intro from './Intro';
import StockCardGrid from './StockCardGrid';
import './BigMoney.css';
import StockCard from './StockCard';
import Spots from './Spots';
import Timer from './Timer';
import Test from './Test';
import React, { useEffect } from 'react';
import GameHeader from './GameHeader';
import GameFieldOverlay from './GameFieldOverlay';
import WeekScore from './WeekScore';

function BigMoney() {
  const [gameStarted, setGameStarted] = React.useState(false);
  const [money, setMoney] = React.useState(10);
  const [spots, setSpots] = React.useState(3);
  const [time, setTime] = React.useState(480);
  const [level, setLevel] = React.useState(0);
  // const [weekScores, setWeekScores] = React.useState([]);

  const startGame = () => {
    setGameStarted(true);
  };

  const stopGame = () => {
    setGameStarted(false);
    console.log('Game is stopped');
  };

  useEffect(() => {
    if (time === 0) {
      stopGame();
    }
  }, [time]);

  useEffect(() => {
    if (gameStarted === false) {
      setTime(480);
      setLevel(prevLevel => prevLevel + 1);
      setSpots(3);
    }
  }, [gameStarted]);

  return (
    <main className='bigmoney'>
      <Intro />
      <div className='gameArea'>
        <GameHeader
          time={time}
          setTime={setTime}
          gameStarted={gameStarted}
          setGameStarted={setGameStarted}
          startGame={startGame}
          money={money}
          level={level}
        />
        <div className='gameField'>
          <StockCardGrid
            gameStarted={gameStarted}
            money={money}
            setMoney={setMoney}
            spots={spots}
            setSpots={setSpots}
            level={level}
          />
          {!gameStarted && (
            <GameFieldOverlay
              startGame={startGame}
              level={level}
              money={money}
            />
          )}
        </div>
        {/* <button onClick={startGame}>Start</button> */}
        <div className='gameFooter'>
          <Spots spots={spots} />
        </div>
        <WeekScore
          money={money}
          level={level}
          setMoney={setMoney}
          setLevel={setLevel}
        />
        {/* <StockCardGrid /> */}
        {/* <StockCard /> */}
        {/* <Test /> */}
      </div>
    </main>
  );
}
export default BigMoney;
