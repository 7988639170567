import React, { useEffect } from 'react';

function StockCard(props) {
  /// TO DO: Reset starting value after each level is reset

  // let level = 1; // just for testing
  let level = props.level;
  const gameStarted = props.gameStarted;
  let money = props.money;
  const setMoney = props.setMoney;
  let spots = props.spots;
  const setSpots = props.setSpots;

  // useEffect(() => {
  //   console.log(level);
  // }, [level]);

  // Randomization functions
  /// startingValue = random between 1/20 & 1/5 money
  const rndStartingValue = () => {
    let min = Math.floor(money / 20);
    let max = Math.floor(money / 5);
    // console.log('New starting value');
    return Math.floor(Math.random() * (max - min) + max);
  };

  /// changeValueTime = random between 100 - 500
  const rndChangeValueTime = () => {
    return Math.floor(Math.random() * 5 + 1) * 100;
  };

  // changeValue = depending on level: 1 => 1-3 * 1-3 | 2 => 1-4 * 1-4 | 3 => 1-5 * 1-5
  const rndChangeValue = () => {
    let rnd1 = Math.floor(Math.random() * (2 + level) + 1);
    let rnd2 = Math.floor(Math.random() * (2 + level) + 1);
    return rnd1 * rnd2;
  };

  // Variables
  // Actions
  const [started, setStarted] = React.useState(false);
  let direction = 'increase';
  const [directionSign, setDirectionSign] = React.useState('up');
  let dropChance = 1;
  const [bought, setBought] = React.useState(false);
  // class names
  const [classNames, setClassNames] = React.useState('stockCard not-started');

  // Amounts
  const [value, setValue] = React.useState(rndStartingValue());
  let changeValue = rndChangeValue();

  // Interval times
  let changeValueTime = rndChangeValueTime();

  // Intervals
  const [startOrNotInterval, setStartOrNotInterval] = React.useState(null);
  const [changeValueInterval, setChangeValueInterval] = React.useState(null);
  const [changeActionInterval, setChangeActionInterval] = React.useState(null);

  // start or not => decide every 3 seconds if bond is started or not
  const startStartOrNotInterval = () => {
    const intervalId = setInterval(() => {
      let rnd = Math.floor(Math.random() * 2);
      console.log('startStartOrNotInterval');
      if (rnd === 1) {
        setStarted(true);
        setValue(rndStartingValue);
        setClassNames(
          prevClassNames =>
            (prevClassNames = prevClassNames.replace('not-started', 'started'))
        );
      } else {
        // console.log('no');
      }
    }, 1000);
    setStartOrNotInterval(intervalId);
  };

  // start randomized startOrNot interval when gameStarted is true (game is actively started) | stop randomized startOrNot interval when gameStarted is false (game has ended)
  useEffect(() => {
    console.log(gameStarted);
    if (gameStarted) {
      startStartOrNotInterval();
    } else if (!gameStarted) {
      stopChanges();
      resetCard();
    }
  }, [gameStarted]);

  // stop startOrNot interval and start changeValue / changeAction intervals when card has started
  useEffect(() => {
    if (started === true) {
      clearInterval(startOrNotInterval);
      startChangeValueInterval();
      startChangeActionInterval();
    }
  }, [started]);

  // change value => change value every x seconds depending on direction and changeValue
  const startChangeValueInterval = () => {
    const intervalId = setInterval(() => {
      console.log('startChangeValueInterval');
      setValue(prevValue => {
        let newValue = prevValue;
        if (direction === 'increase') {
          newValue += changeValue;
        } else if (direction === 'decrease' && newValue >= changeValue) {
          newValue -= changeValue;
        } else if (direction === 'decrease' && newValue < changeValue) {
          newValue = 0;
        }
        return newValue;
      });
    }, changeValueTime);
    setChangeValueInterval(intervalId);
  };

  // change action => run every 2.5 seconds random 1-9 | 1-3: set new changeValue | 4-dropChance: change direction to decrease | else: nothing
  const startChangeActionInterval = () => {
    const intervalId = setInterval(() => {
      console.log('startChangeActionInterval');
      let rnd = Math.floor(Math.random() * 9 + 1);
      if (rnd <= 3) {
        changeValue = rndChangeValue();
      } else if (rnd > 3 && rnd < 3 + dropChance) {
        direction = 'decrease';
        setDirectionSign('down');
      }
      dropChance += 1;
    }, 2500);
    setChangeActionInterval(intervalId);
  };

  // stop functions
  /// stop change intervals
  const stopChanges = () => {
    console.log('Stopped');
    setStarted(false);
    clearInterval(startOrNotInterval);
    clearInterval(changeValueInterval);
    clearInterval(changeActionInterval);
    // setValue(rndStartingValue());
  };

  /// restart card's startOrNot interval
  const restartCard = () => {
    setTimeout(() => startStartOrNotInterval(), 3000);
  };

  /// reset card visually and card's states
  const resetCard = () => {
    setClassNames('stockCard not-started');
    direction = 'increase';
    setDirectionSign('up');
    dropChance = 1;
    setBought(false);
  };

  // stop all intervals when value = 0
  useEffect(() => {
    if (value === 0) {
      stopChanges();
      resetCard();
      if (gameStarted) {
        restartCard();
      }
    }
  }, [value]);

  // vibrate on error
  const vibrate = () => {
    if (!classNames.includes('vibrate')) {
      setClassNames(prevClassNames => (prevClassNames += ' vibrate-left'));

      setTimeout(
        () =>
          setClassNames(prevClassNames =>
            prevClassNames.replace('vibrate-left', 'vibrate-right')
          ),
        50
      );

      setTimeout(
        () =>
          setClassNames(prevClassNames =>
            prevClassNames.replace('vibrate-right', '')
          ),
        100
      );
    }
  };

  // buying / selling functionality
  const buyOrSell = () => {
    if (bought === false) {
      if (money >= value && spots > 0) {
        setMoney(prevMoney => (prevMoney -= value));
        setClassNames(prevClassNames => (prevClassNames += ' bought'));
        setSpots(prevSpots => (prevSpots -= 1));
        setBought(true);
      } else {
        vibrate();
      }
    } else if (bought === true) {
      setMoney(prevMoney => (prevMoney += value));
      setClassNames(prevClassNames => prevClassNames.replace('bought', ''));
      setSpots(prevSpots => (prevSpots += 1));
      setBought(false);
    }
  };

  // Just for testing
  useEffect(() => {
    // console.log(value);
  }, [value]);

  useEffect(() => {
    // console.log(dropChance);
  }, [dropChance]);

  return (
    <>
      <div className={classNames} onClick={buyOrSell}>
        {directionSign === 'up' && (
          <span className='material-symbols-outlined trend-up'>
            trending_up
          </span>
        )}
        {directionSign === 'down' && (
          <span className='material-symbols-outlined trend-down'>
            trending_down
          </span>
        )}
        <p>{value}</p>
      </div>
    </>
  );
}

export default StockCard;
