function Intro() {
  return (
    <section className='intro'>
      <h2>Let's play a game!</h2>
      <p>
        Being all adult and serious is great, but have you tried playing a
        little game to unwind? This is a collection of self-coded mini games to
        let you relax for a while, some of them classics and some of them my own
        brain-children.
      </p>
      <p>
        TicTacToe lets you play against an AI, with bingo you can unleash your
        inner old lady, and the weather game takes you on a meteorological quiz
        journey to places all over the world.
      </p>
      <p>There's only one thing left to say now: glhf (iykyk).</p>
    </section>
  );
}

export default Intro;
