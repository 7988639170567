function StartOverlay(props) {
  let start = props.start;
  const setStart = props.setStart;
  const startGame = () => setStart(false);
  return (
    <div className='fullOverlay lostOverlay'>
      <h3>Let the guessing begin!</h3>
      <div className='single-button'>
        <button className='button btn-highlight' onClick={startGame}>
          Start game
        </button>
      </div>
    </div>
  );
}

export default StartOverlay;
