function WinningMessage(props) {
  return (
    <div className='winningMessageContainer'>
      <p className='winningMessage'>BINGO!</p>
      <button className='button btn-highlight' onClick={props.clickHandler}>
        New Game
      </button>
    </div>
  );
}

export default WinningMessage;
