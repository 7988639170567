import { Route, Routes, Link } from 'react-router-dom';
import './ContentCard.css';

function ContentCard(props) {
  return (
    <Link to={props.link} className='nolink'>
      <div className='contentCard'>
        <span class='material-symbols-outlined'>{props.symbol}</span>

        {/* <i className='material-icons'></i> */}
        <p>{props.copy}</p>
      </div>
    </Link>
  );
}

export default ContentCard;
