function GameFieldOverlay(props) {
  const startGame = props.startGame;
  const level = props.level;
  const money = props.money;

  return (
    <div className='overlay'>
      <h4>
        {level === 0 ? 'TGIF - you made it to the weekend!' : `Level ${level}`}
      </h4>
      {/* {level != 0 && (<h4>Level {level}</h4>)}
      {level === 0 && (<h4>TGIF! You made it to the weekend!</h4>)} */}
      {level === 0 && (
        <p className='weekResults'>
          You made a whooping {money}$ this week! Take some rest and relax.
          Monday is just around the corner, and you'll start over again.
        </p>
      )}
      {/* <h3>Start Game</h3> */}
      {/* <h4>Level {level}</h4> */}
      <button className='button btn-highlight' onClick={startGame}>
        {level === 0 ? 'Start new week' : 'Next day'}
      </button>
    </div>
  );
}

export default GameFieldOverlay;

// startGame={startGame} level={level} showOverlay={showOverlay} setShowOverlay={setShowOverlay}
