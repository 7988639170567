function Intro() {
  return (
    <div className='intro'>
      <h1>Bingo</h1>
      <p>
        Channel your inner old lady and play a round of bingo. Simply match the
        numbers on the game field to the five drawn numbers above and cross your
        fingers for a full line, row or diagonal. If you want a new round of
        drawn numbers, click the button that says 'Draw' (d'oh). Have fun and
        good luck!
      </p>
    </div>
  );
}

export default Intro;
