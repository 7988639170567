import Hero from '../../app_components/Hero';
import ContentCard from '../../app_components/ContentCard';
import ContentCardContainer from '../../app_components/ContentCardContainer';

import Intro from './Intro';

function Games() {
  return (
    <>
      <Hero
        h1="It's all fun and games..."
        p='... until someone gets hurt. Just kidding. No one gets hurt here.'
        contact={false}
        ctaText='Get in touch'
      />
      <main>
        <Intro />
        <ContentCardContainer
          padding='20%'
          mobilePadding='5%'
          columns='3'
          content={
            <>
              <ContentCard
                symbol='grid_3x3'
                copy='TicTacToe'
                link='/tictactoe'
              />
              <ContentCard symbol='ifl' copy='Bingo' link='/bingo' />
              <ContentCard
                symbol='partly_cloudy_day'
                copy='Weather'
                link='/weather'
              />
            </>
          }
        />
        <button
          className='button btn-subtle'
          onClick={() =>
            (window.location.href = 'mailto:koskelanadja@gmail.com')
          }
        >
          Let's talk
        </button>
      </main>
    </>
  );
}

export default Games;
