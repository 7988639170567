function Spots(props) {
  let spotScale = [];
  for (let i = 0; i < 3; i++) {
    let newSpot =
      props.spots > i ? (
        <span key={i} className='material-symbols-outlined spot-free'>
          credit_card
        </span>
      ) : (
        <span key={i} className='material-symbols-outlined spot-taken'>
          credit_score
        </span>
      );
    spotScale.push(newSpot);
  }
  return <div className='spots'>{spotScale}</div>;
}

export default Spots;
