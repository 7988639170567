// import logo from './logo.svg';
import './App.css';
import { Route, Routes, Link } from 'react-router-dom';

// import pages
import HomePage from './pages/homepage/HomePage';
import TicTacToe from './pages/tictactoe/TicTacToe';
import Bingo from './pages/bingo/Bingo';
import Weather from './pages/weather/Weather';
import Consulting from './pages/consulting/Consulting';
import BigMoney from './pages/bigmoney/BigMoney';
import Games from './pages/games/Games';
// import app componentss
import Contact from './app_components/ContactButton';
import Footer from './app_components/Footer';
import TopNavigation from './app_components/TopNavigation';
import Hero from './app_components/Hero';
import ContentCard from './app_components/ContentCard';

function App() {
  return (
    <div className='App'>
      <TopNavigation />
      <Routes>
        <Route path='/' element={<HomePage />}></Route>
        <Route path='/tictactoe' element={<TicTacToe />}></Route>
        <Route path='/bingo' element={<Bingo />}></Route>
        <Route path='/weather' element={<Weather />}></Route>
        <Route path='/consulting' element={<Consulting />}></Route>
        <Route path='/bigmoney' element={<BigMoney />}></Route>
        <Route path='/games' element={<Games />}></Route>
      </Routes>

      {/* <Routes>
          <Route path='/' element={<HomePage />}></Route>
          <Route path='/tictactoe' element={<TicTacToe />}></Route>
          <Route path='/bingo' element={<Bingo />}></Route>
          <Route path='/weather' element={<Weather />}></Route>
        </Routes> */}
      {/* </main> */}
      <Footer />
    </div>
  );
}

export default App;
