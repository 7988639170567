import randomCapital from './randomCapital';
import React, { useEffect } from 'react';
import getCurrentWeather from './getCurrentWeather';
import Guessing from './Guessing';
import GuessingButton from './GuessingButton';
import ResultsOverlay from './ResultsOverlay';
import './Weather.css';
import GameHeader from './GameHeader';
import Intro from './Intro';
import WeatherQuestion from './WeatherQuestion';
import LostOverlay from './LostOverlay';
import StartOverlay from './StartOverlay';

function Weather() {
  const defaultActualData = {
    city: '',
    country: '',
    lat: '',
    lon: '',
    temp: '',
    temp_kelvin: '',
    wind: '',
    wind_speed: '',
    clouds: '',
    clouds_coverage: '',
    rain: '',
    snow: '',
    date: 'today',
  };

  const defaultGuessedData = {
    temp: 15,
    wind: 'wind-00',
    clouds: 'clouds-00',
    rain: '',
    snow: '',
  };

  const defaultResults = {
    temp: '',
    wind: '',
    clouds: '',
    precipitation: '',
    points: 0,
    fail: false,
  };

  const [actualData, setActualData] = React.useState(defaultActualData);
  const [guessedData, setGuessedData] = React.useState(defaultGuessedData);
  const [guessMade, setGuessMade] = React.useState(false);
  const [results, setResults] = React.useState(defaultResults);
  const [points, setPoints] = React.useState(0);
  const [lives, setLives] = React.useState(3);
  const [gameMode, setGameMode] = React.useState('today');
  const [lost, setLost] = React.useState(false);
  const [start, setStart] = React.useState(true);

  // generate game data
  useEffect(() => {
    randomCapital(actualData, setActualData);
  }, []);

  useEffect(() => {
    if (gameMode === 'today') {
      getCurrentWeather(actualData, setActualData);
    } // comment out in dev mode
  }, [actualData.lat]);

  //   Testing
  useEffect(() => {
    // console.log(actualData);
  }, [actualData]);

  useEffect(() => {
    // console.log(guessedData);
  }, [guessedData]);

  useEffect(() => {
    // console.log(results);
  }, [results]);

  useEffect(() => {
    // console.log(actualData);
    // console.log(guessedData);
  }, [guessMade]);

  //   Testing End

  const newRound = function () {
    setActualData(defaultActualData);
    setGuessedData(defaultGuessedData);
    setResults(defaultResults);
    setGuessMade(false);
    randomCapital(actualData, setActualData);
  };

  const newGame = function () {
    newRound();
    setLives(3);
    setPoints(0);
    setLost(false);
  };

  return (
    <main className='weather'>
      <Intro />
      <div className='gameArea'>
        <GameHeader points={points} lives={lives} />
        <WeatherQuestion gameMode={gameMode} actualData={actualData} />
        <Guessing
          guessedData={guessedData}
          setGuessedData={setGuessedData}
          guessMade={guessMade}
          gameMode={gameMode}
        />

        <GuessingButton
          guessedData={guessedData}
          actualData={actualData}
          setActualData={setActualData}
          guessMade={guessMade}
          setGuessMade={setGuessMade}
          results={results}
          setResults={setResults}
          points={points}
          setPoints={setPoints}
          lives={lives}
          setLives={setLives}
        />
        {guessMade && (
          <ResultsOverlay
            guessedData={guessedData}
            actualData={actualData}
            setData={setActualData}
            results={results}
            guessMade={guessMade}
            newRound={newRound}
            gameMode={gameMode}
          />
        )}
        {lost && <LostOverlay points={points} newGame={newGame} />}
        {start && <StartOverlay start={start} setStart={setStart} />}
      </div>
    </main>
  );
}

export default Weather;
