import './Footer.css';

function Footer() {
  return (
    <footer>
      <p>© Nadja Koskela, 2024</p>
    </footer>
  );
}

export default Footer;
