function ChooseDifficulty(props) {
  const increaseDifficulty = () => {
    props.difficulty === 'easy'
      ? props.setDifficulty('medium')
      : props.setDifficulty('hard');
  };
  const decreaseDifficulty = () => {
    props.difficulty === 'hard'
      ? props.setDifficulty('medium')
      : props.setDifficulty('easy');
  };

  return (
    <div className='chooseDifficulty'>
      <span
        className='material-icons difficulty-arrow'
        onClick={decreaseDifficulty}
      >
        arrow_left
      </span>
      <span>{props.difficulty}</span>
      <span
        className='material-icons difficulty-arrow'
        onClick={increaseDifficulty}
      >
        arrow_right
      </span>
    </div>
  );
}

export default ChooseDifficulty;
