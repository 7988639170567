function Intro() {
  return (
    <div className='intro'>
      <h1>TicTacToe</h1>
      <p>
        Tic Tac Toe, Three-in-a-row or XXO - the principle is easy: try to get
        three Xs in a row. The computer has the same goal though and will
        challenge you to it. Will you beat the machine?
      </p>
    </div>
  );
}

export default Intro;
