function Intro() {
  return (
    <div className='intro'>
      <h1>Guess the weather</h1>
      <p>
        Have you ever wondered where the weather forecast comes from, and
        doesn't it feel like quite some guessing is involved there? Great news:
        you can now participate in the weather guessing game too. Just take a
        guess or estimate - depending on your meteorological knowledge - for a
        random place in the world and collect as many points as you can. Happy
        guessing!
      </p>
    </div>
  );
}

export default Intro;
