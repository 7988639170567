import React, { useEffect } from 'react';

function WeekScore(props) {
  let money = props.money;
  let level = props.level;
  let setMoney = props.setMoney;
  let setLevel = props.setLevel;

  const [weekScores, setWeekScores] = React.useState([]);

  const addWeek = () => {
    let newWeek = level / 5;
    let newMoney = money;
    let newWeekScores = [...weekScores];
    newWeekScores.push(`Week ${newWeek}: ${newMoney} $`);
    setWeekScores(newWeekScores);
  };

  useEffect(() => {
    if (level > 0 && level % 5 === 0) {
      addWeek();
      setLevel(0);
      setMoney(10);
      console.log('New week');
    }
  }, [level]);

  return (
    <div className='weekScores'>
      <h4>Week Scores</h4>
      {weekScores}
    </div>
  );
}

export default WeekScore;
