function WeatherQuestion(props) {
  let question =
    props.gameMode === 'today'
      ? `How is the weather right now in ${props.actualData.city}, ${props.actualData.country}?`
      : '';
  return (
    <div className='weatherQuestion'>
      <p>{question}</p>
    </div>
  );
}

export default WeatherQuestion;
