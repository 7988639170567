import TemperatureGuess from './TemperatureGuess';
import CloudButtons from './CloudButtons';
import WindButtons from './WindButtons';
import PrecipitationButtons from './PrecipitationButtons';
import React, { useEffect } from 'react';

function ResultsOverlay(props) {
  const guessedData = props.guessedData; //
  const actualData = props.actualData; //
  const results = props.results; //
  const setData = props.setData;
  const guessMade = props.guessMade; //
  const gameMode = props.gameMode;
  const [fail, setFail] = React.useState(false);

  //   TestingData

  // const guessedData = {
  //   temp: '23',
  //   wind: 'wind-01',
  //   clouds: 'clouds-01',
  //   rain: 'true',
  //   snow: '',
  // };

  // const actualData = {
  //   city: 'Test',
  //   country: 'Test',
  //   lat: 'asd',
  //   lon: 'asd',
  //   temp: 55,
  //   wind: 9,
  //   windrange: 2,
  //   clouds: 56,
  //   cloudrange: 2,
  //   rain: true,
  //   snow: false,
  //   date: 'today',
  // };

  // const results = {
  //   temp: 'good',
  //   wind: 'pass',
  //   clouds: 'good',
  //   precipitation: 'pass',
  //   points: 30,
  //   fail: false,
  // };

  // const guessMade = true;

  let resultsSymbolClass = t =>
    results[t] === 'good'
      ? 'material-symbols-outlined symb-good'
      : results[t] === 'pass'
      ? 'material-symbols-outlined symb-pass'
      : 'material-symbols-outlined symb-fail';

  let resultsSymbolContent = t =>
    results[t] === 'good'
      ? 'emoji_events'
      : results[t] === 'pass'
      ? 'sentiment_neutral'
      : 'close';

  // console.log('Temp: ', results['temp']);
  // console.log('Clouds: ', results['clouds']);
  // console.log('Wind: ', results['wind']);

  let ResultsSummary = () => {
    let resultsRating =
      results.fail === true
        ? 'Oh no! Better luck next round!'
        : gameMode === 'today'
        ? results.points === 0
          ? 'Oh no!'
          : results.points === 25
          ? 'A perfect guess!'
          : results.points === 15 || results.points === 20
          ? 'Great job!'
          : 'Quite okay!'
        : '';

    return (
      <div className='resultsSummary'>
        <h4 className='resultsRating'>{resultsRating}</h4>
        {(!fail || (fail && results.points !== 0)) && (
          <p className='resultsPoints'>+ {String(results.points)}</p>
        )}
        {fail && (
          <span className='material-symbols-outlined failed-heart'>
            heart_broken
          </span>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (results.fail) {
      setFail(true);
    }
  }, [results.fail]);

  return (
    <div className='fullOverlay resultsOverlay'>
      <ResultsSummary />
      <p className='resultsCity'>
        The weather in {actualData.city}, {actualData.country}
      </p>
      <div className='resultsDetails' id='resultsHeader'>
        <p>Your guess</p>
        <p> </p>
        <p>Actual weather</p>
      </div>
      <div className='resultsDetails' id='resultsTemperature'>
        <TemperatureGuess
          data={guessedData}
          setData={setData}
          inactive={guessMade}
        />
        <span className={resultsSymbolClass('temp')}>
          {resultsSymbolContent('temp')}
        </span>
        <TemperatureGuess
          data={actualData}
          setData={setData}
          inactive={guessMade}
        />
      </div>
      <div className='resultsDetails' id='resultsClouds'>
        <CloudButtons
          data={guessedData}
          setData={setData}
          inactive={guessMade}
        />
        <span className={resultsSymbolClass('clouds')}>
          {resultsSymbolContent('clouds')}
        </span>
        <CloudButtons
          data={actualData}
          setData={setData}
          inactive={guessMade}
        />
      </div>
      <div className='resultsDetails' id='resultsWind'>
        <WindButtons
          data={guessedData}
          setData={setData}
          inactive={guessMade}
        />
        <span className={resultsSymbolClass('wind')}>
          {resultsSymbolContent('wind')}
        </span>

        <WindButtons data={actualData} setData={setData} inactive={guessMade} />
      </div>
      <div className='single-button'>
        <button className='button btn-subtle' onClick={props.newRound}>
          New Round
        </button>
      </div>
    </div>
  );
}

export default ResultsOverlay;
