function LostOverlay(props) {
  return (
    <div className='fullOverlay lostOverlay'>
      <h4>You lost!</h4>
      <p className='finalScore'>Your score: {props.points}</p>
      <p>
        But you sure had a lot of fun playing. Give it another try and explore
        the world's weather.
      </p>
      <div className='single-button'>
        <button className='button btn-highlight' onClick={props.newGame}>
          New Game
        </button>
      </div>
    </div>
  );
}

export default LostOverlay;
