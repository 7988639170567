import React, { useEffect } from 'react';

function Timer(props) {
  const gameStarted = props.gameStarted;
  const setGameStarted = props.gameStarted;
  const [h, setH] = React.useState(Math.floor(props.time / 60));
  const [m, setM] = React.useState(props.time % 60);
  const [timerInterval, setTimerInterval] = React.useState(null);

  const [timerStarted, setTimerStarted] = React.useState(false);

  const startTimerInterval = () => {
    const intervalId = setInterval(() => {
      props.setTime(prevTime => prevTime - 1);
    }, 10);
    setTimerInterval(intervalId);
  };

  useEffect(() => {
    // if (h === 0 && m === 0)
    if (props.time === 0) {
      setH(17);
      setM(0);
      clearInterval(timerInterval);
    } else {
      let newH =
        Math.floor(props.time / 60) === 8
          ? 9
          : 9 + (7 - Math.floor(props.time / 60));
      setH(newH);
      let newM = props.time % 60 === 0 ? 0 : 60 - (props.time % 60);
      setM(newM);
    }
  }, [props.time]);

  //   useEffect(() => {
  //     if (h === 0 && m === 0) {
  //       clearInterval(timerInterval);
  //     } else {
  //       let newH = Math.floor(props.time / 60);
  //       setH(newH);
  //       let newM = props.time % 60;
  //       setM(newM);
  //     }
  //   }, [props.time]);

  useEffect(() => {
    if (gameStarted) {
      startTimerInterval();
    }
  }, [gameStarted]);

  return (
    <div className='timer'>
      {/* <button onClick={startTimerInterval}>Start</button> */}
      <p className='gameScore'>
        {h}:{String(m).padStart(2, '0')}
      </p>
    </div>
  );
}

export default Timer;
