import Timer from './Timer';
import React, { useEffect } from 'react';

function GameHeader(props) {
  const time = props.time;
  // const time = 80;
  const setTime = props.setTime;
  const gameStarted = props.gameStarted;
  const setGameStarted = props.setGameStarted;
  const money = props.money;
  const level = props.level;
  // let level = 1;

  // const [showDay, setShowDay] = React.useState('');
  // let day = '';

  const [day, setDay] = React.useState('');

  useEffect(() => {
    switch (level) {
      case 1:
        setDay('Monday');
        break;
      case 2:
        setDay('Tuesday');
        break;
      case 3:
        setDay('Wednesday');
        break;
      case 4:
        setDay('Thursday');
        break;
      default:
        setDay('Friday');
    }
  }, [level]);

  // const ShowDay = () => {
  //   const [day, setDay] = React.useState('');
  //   switch (level) {
  //     case 1:
  //       setDay('Monday');
  //       break;
  //     case 2:
  //       setDay('Tuesday');
  //       break;
  //     case 3:
  //       setDay('Wednesday');
  //       break;
  //     case 4:
  //       setDay('Thursday');
  //       break;
  //     default:
  //       setDay('Friday');
  //   }

  //   return <p className='gameScore'>{day}</p>;
  // };

  // switch (level) {
  //   case 1:
  //     day = 'Monday';
  //     break;
  //   case 2:
  //     day = 'Tuesday';
  //     break;
  //   case 3:
  //     day = 'Wednesday';
  //     break;
  //   case 4:
  //     day = 'Thursday';
  //     break;
  //   case 5:
  //     day = 'Friday';
  //     break;
  // }

  // useEffect(() => {
  //   setShowDay(day);
  // }, [day]);

  const increaseLevel = () => {
    console.log(level);
    level += 1;
    console.log(level);
  };

  return (
    <div className='gameHeader'>
      {/* <button onClick={increaseLevel}>Increase</button> */}
      <div className='gameHeaderCol first-col'>
        <p className='game-info'>9:00 - 5:00</p>
        <Timer
          time={time}
          setTime={setTime}
          gameStarted={gameStarted}
          setGameStarted={setGameStarted}
        />
      </div>
      <div className='gameHeaderCol middle-col'>
        <p className='game-info'>Monday - Friday</p>
        <p className='gameScore'>{day}</p>
        {/* <ShowDay /> */}
      </div>
      <div className='gameHeaderCol right-col'>
        <p className='gameScore'>{money} $</p>
      </div>
    </div>
  );
}

export default GameHeader;
