import { Link } from 'react-router-dom';
import './Hero.css';
import hero_bg_tiles from '../assets/images/hero-bg-tiles.jpg';

function Hero(props) {
  return (
    <>
      <header
        className='hero'
        style={{
          backgroundImage: `url(${hero_bg_tiles})`,
          backgroundSize: 'cover',
        }}
      >
        <div className='hero-box'>
          <div className='hero-content'>
            <h1>{props.h1}</h1>
            {props.contact && <p>{props.p}</p>}
            {!props.contact && <p className='last'>{props.p}</p>}
            {props.contact && (
              <div className='hero-contact-cta'>
                <button
                  className='button btn-subtle'
                  onClick={() =>
                    (window.location.href = 'mailto:koskelanadja@gmail.com')
                  }
                >
                  {' '}
                  {props.ctaText}
                </button>
                {/* <Link
                className='button btn-subtle'
                onClick={() =>
                  (window.location.href = 'mailto:koskelanadja@gmail.com')
                }
              >
                {props.ctaText}
              </Link> */}
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
}

export default Hero;
