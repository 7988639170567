import React from 'react';
// import './GuessingButtons.css';
import CloudButtons from './CloudButtons';
import WindButtons from './WindButtons';
import PrecipitationButtons from './PrecipitationButtons';
import TemperatureGuess from './TemperatureGuess';

function Guessing(props) {
  return (
    <div className='guessing'>
      <TemperatureGuess
        data={props.guessedData}
        setData={props.setGuessedData}
        inactive={props.guessMade}
      />
      <CloudButtons
        data={props.guessedData}
        setData={props.setGuessedData}
        inactive={props.guessMade}
      />
      <WindButtons
        data={props.guessedData}
        setData={props.setGuessedData}
        inactive={props.guessMade}
      />
      {props.gameMode !== 'today' && (
        <PrecipitationButtons
          data={props.guessedData}
          setData={props.setGuessedData}
          inactive={props.guessMade}
        />
      )}
    </div>
  );
}

export default Guessing;
