function checkPattern(arr) {
  const winningPatterns = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];

  for (let i = 0; i < winningPatterns.length; i++) {
    let i0 = winningPatterns[i][0];
    let i1 = winningPatterns[i][1];
    let i2 = winningPatterns[i][2];
    if (arr[i0] !== '' && arr[i0] === arr[i1] && arr[i1] === arr[i2]) {
      if (arr[i0] === 'X') {
        return 'playerWon';
      } else if (arr[i0] === 'O') {
        return 'computerWon';
      }
    }
  }
  return '';
}

export default checkPattern;
