import React, { useEffect, useState } from 'react';

function TemperatureGuess(props) {
  const [temperature, setTemperature] = React.useState(0);
  const inactive = props.inactive;

  useEffect(() => {
    if (!inactive) {
      setTemperature(props.data.temp || 0);
    }
  }, [props.data.temp]);

  const temperatureInput = e => {
    setTemperature(Number(e.target.value));
  };

  const [changeTemperatureInterval, setChangeTemperatureInterval] =
    useState(null);

  const startChangeTemperature = n => {
    setTemperature(prevTemperature => prevTemperature + n);
    setChangeTemperatureInterval(
      setInterval(() => {
        setTemperature(prevTemperature => prevTemperature + n);
      }, 300)
    );
  };

  const stopChangeTemperature = () => {
    clearInterval(changeTemperatureInterval);
  };

  useEffect(() => {
    if (!inactive) {
      let newData = { ...props.data };
      newData.temp = temperature;
      props.setData(newData);
    }
  }, [temperature]);

  return (
    <div className='temperatureGuess guessing-buttons'>
      {!inactive && (
        <div
          className='temperature-button button btn-subtle'
          onPointerDown={() => startChangeTemperature(-1)}
          onPointerUp={stopChangeTemperature}
        >
          <span className='material-symbols-outlined'>remove</span>
        </div>
      )}
      {!inactive && (
        <div className='temperatureInput'>
          <input
            id='temperature'
            name='temperature'
            pattern='^-?\d*\.?\d*$'
            value={temperature}
            onChange={temperatureInput}
          ></input>
          <p>°C</p>
        </div>
      )}
      {inactive && (
        <div className='temperatureOutput'>
          <p>{Math.floor(props.data.temp)}°C</p>
        </div>
      )}
      {!inactive && (
        <div
          className='temperature-button button btn-subtle'
          onPointerDown={() => startChangeTemperature(1)}
          onPointerUp={stopChangeTemperature}
        >
          <span className='material-symbols-outlined'>add</span>
        </div>
      )}
    </div>
  );
}

export default TemperatureGuess;
