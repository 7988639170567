import StockCard from './StockCard';

function StockCardGrid(props) {
  let stockCards = [];
  for (let i = 0; i < 16; i++) {
    stockCards.push(
      <StockCard
        gameStarted={props.gameStarted}
        money={props.money}
        setMoney={props.setMoney}
        spots={props.spots}
        setSpots={props.setSpots}
        level={props.level}
      />
    );
  }
  return (
    <div className='stockCardGrid'>
      {stockCards}
      {/* <StockCard
        gameStarted={props.gameStarted}
        money={props.money}
        setMoney={props.setMoney}
        spots={props.spots}
        setSpots={props.setSpots}
      />
      <StockCard
        gameStarted={props.gameStarted}
        money={props.money}
        setMoney={props.setMoney}
        spots={props.spots}
        setSpots={props.setSpots}
      />
      <StockCard
        gameStarted={props.gameStarted}
        money={props.money}
        setMoney={props.setMoney}
        spots={props.spots}
        setSpots={props.setSpots}
      />
      <StockCard
        gameStarted={props.gameStarted}
        money={props.money}
        setMoney={props.setMoney}
        spots={props.spots}
        setSpots={props.setSpots}
      /> */}
      {/* <StockCard /> */}
      {/* <StockCard /> */}
      {/* <StockCard /> */}
    </div>
  );
}

export default StockCardGrid;
