const capitalList = [
  { city: 'Kabul', country: 'Afghanistan', lat: 34.28, lon: 69.11 },
  { city: 'Tirane', country: 'Albania', lat: 41.18, lon: 19.49 },
  { city: 'Algiers', country: 'Algeria', lat: 36.42, lon: 3.08 },
  { city: 'Pago Pago', country: 'American Samoa', lat: 14.16, lon: 17.43 },
  { city: 'Andorra la Vella', country: 'Andorra', lat: 42.31, lon: 1.32 },
  { city: 'Luanda', country: 'Angola', lat: 8.5, lon: 13.15 },
  { city: "St. John's", country: 'Antigua and Barbuda', lat: 17.2, lon: 61.48 },
  { city: 'Buenos Aires', country: 'Argentina', lat: 36.3, lon: 60.0 },
  { city: 'Yerevan', country: 'Armenia', lat: 40.1, lon: 44.31 },
  { city: 'Oranjestad', country: 'Aruba', lat: 12.32, lon: 70.02 },
  { city: 'Canberra', country: 'Australia', lat: 35.15, lon: 14.08 },
  { city: 'Vienna', country: 'Austria', lat: 48.12, lon: 16.22 },
  { city: 'Baku', country: 'Azerbaijan', lat: 40.29, lon: 49.56 },
  { city: 'Nassau', country: 'Bahamas', lat: 25.05, lon: 77.2 },
  { city: 'Manama', country: 'Bahrain', lat: 26.1, lon: 50.3 },
  { city: 'Dhaka', country: 'Bangladesh', lat: 23.43, lon: 90.26 },
  { city: 'Bridgetown', country: 'Barbados', lat: 13.05, lon: 59.3 },
  { city: 'Minsk', country: 'Belarus', lat: 53.52, lon: 27.3 },
  { city: 'Brussels', country: 'Belgium', lat: 50.51, lon: 4.21 },
  { city: 'Belmopan', country: 'Belize', lat: 17.18, lon: 88.3 },
  {
    city: 'Porto-Novo',
    country: 'Benin',
    lat: 6.23,
    lon: 2.42,
  },
  { city: 'Thimphu', country: 'Bhutan', lat: 27.31, lon: 89.45 },
  {
    city: 'La Paz',
    country: 'Bolivia',
    lat: 16.2,
    lon: 68.1,
  },
  {
    city: 'Sarajevo',
    country: 'Bosnia and Herzegovina',
    lat: 43.52,
    lon: 18.26,
  },
  { city: 'Gaborone', country: 'Botswana', lat: 24.45, lon: 25.57 },
  { city: 'Brasilia', country: 'Brazil', lat: 15.47, lon: 47.55 },
  {
    city: 'Road Town',
    country: 'British Virgin Islands',
    lat: 18.27,
    lon: 64.37,
  },
  {
    city: 'Bandar Seri Begawan',
    country: 'Brunei Darussalam',
    lat: 4.52,
    lon: 11.0,
  },
  { city: 'Sofia', country: 'Bulgaria', lat: 42.45, lon: 23.2 },
  { city: 'Ouagadougou', country: 'Burkina Faso', lat: 12.15, lon: 1.3 },
  { city: 'Bujumbura', country: 'Burundi', lat: 3.16, lon: 29.18 },
  { city: 'Phnom Penh', country: 'Cambodia', lat: 11.33, lon: 10.55 },
  { city: 'Yaounde', country: 'Cameroon', lat: 3.5, lon: 11.35 },
  { city: 'Ottawa', country: 'Canada', lat: 45.27, lon: 75.42 },
  { city: 'Praia', country: 'Cape Verde', lat: 15.02, lon: 23.34 },
  { city: 'George Town', country: 'Cayman Islands', lat: 19.2, lon: 81.24 },
  {
    city: 'Bangui',
    country: 'Central African Republic',
    lat: 4.23,
    lon: 18.35,
  },
  { city: "N'Djamena", country: 'Chad', lat: 12.1, lon: 14.59 },
  { city: 'Santiago', country: 'Chile', lat: 33.24, lon: 70.4 },
  { city: 'Beijing', country: 'China', lat: 39.55, lon: 11.2 },
  { city: 'Bogota', country: 'Colombia', lat: 4.34, lon: 74.0 },
  { city: 'Moroni', country: 'Comros', lat: 11.4, lon: 43.16 },
  { city: 'Brazzaville', country: 'Congo', lat: 4.09, lon: 15.12 },
  { city: 'San Jose', country: 'Costa Rica', lat: 9.55, lon: 84.02 },
  { city: 'Yamoussoukro', country: "Cote d'Ivoire", lat: 6.49, lon: 5.17 },
  { city: 'Zagreb', country: 'Croatia', lat: 45.5, lon: 15.58 },
  { city: 'Havana', country: 'Cuba', lat: 23.08, lon: 82.22 },
  { city: 'Nicosia', country: 'Cyprus', lat: 35.1, lon: 33.25 },
  { city: 'Prague', country: 'Czech Republic', lat: 50.05, lon: 14.22 },
  {
    city: "P'yongyang",
    country: "Democratic People's Republic of Korea",
    lat: 39.09,
    lon: 12.3,
  },
  {
    city: 'Kinshasa',
    country: 'Democratic Republic of the Congo',
    lat: 4.2,
    lon: 15.15,
  },
  { city: 'Copenhagen', country: 'Denmark', lat: 55.41, lon: 12.34 },
  { city: 'Djibouti', country: 'Djibouti', lat: 11.08, lon: 42.2 },
  { city: 'Roseau', country: 'Dominica', lat: 15.2, lon: 61.24 },
  {
    city: 'Santo Domingo',
    country: 'Dominica Republic',
    lat: 18.3,
    lon: 69.59,
  },
  { city: 'Dili', country: 'East Timor', lat: 8.29, lon: 12.34 },
  { city: 'Quito', country: 'Ecuador', lat: 0.15, lon: 78.35 },
  { city: 'Cairo', country: 'Egypt', lat: 30.01, lon: 31.14 },
  { city: 'San Salvador', country: 'El Salvador', lat: 13.4, lon: 89.1 },
  { city: 'Malabo', country: 'Equatorial Guinea', lat: 3.45, lon: 8.5 },
  { city: 'Asmara', country: 'Eritrea', lat: 15.19, lon: 38.55 },
  { city: 'Tallinn', country: 'Estonia', lat: 59.22, lon: 24.48 },
  { city: 'Addis Ababa', country: 'Ethiopia', lat: 9.02, lon: 38.42 },
  {
    city: 'Stanley',
    country: 'Falkland Islands',
    lat: 51.4,
    lon: 59.51,
  },
  { city: 'Torshavn', country: 'Faroe Islands', lat: 62.05, lon: 6.56 },
  { city: 'Suva', country: 'Fiji', lat: 18.06, lon: 17.3 },
  { city: 'Helsinki', country: 'Finland', lat: 60.15, lon: 25.03 },
  { city: 'Paris', country: 'France', lat: 48.5, lon: 2.2 },
  { city: 'Cayenne', country: 'French Guiana', lat: 5.05, lon: 52.18 },
  { city: 'Papeete', country: 'French Polynesia', lat: 17.32, lon: 14.34 },
  { city: 'Libreville', country: 'Gabon', lat: 0.25, lon: 9.26 },
  { city: 'Banjul', country: 'Gambia', lat: 13.28, lon: 16.4 },
  { city: "T'bilisi", country: 'Georgia', lat: 41.43, lon: 44.5 },
  { city: 'Berlin', country: 'Germany', lat: 52.3, lon: 13.25 },
  { city: 'Accra', country: 'Ghana', lat: 5.35, lon: 0.06 },
  { city: 'Athens', country: 'Greece', lat: 37.58, lon: 23.46 },
  { city: 'Nuuk', country: 'Greenland', lat: 64.1, lon: 51.35 },
  { city: 'Basse-Terre', country: 'Guadeloupe', lat: 16.0, lon: 61.44 },
  { city: 'Guatemala', country: 'Guatemala', lat: 14.4, lon: 90.22 },
  { city: 'St. Peter Port', country: 'Guernsey', lat: 49.26, lon: 2.33 },
  { city: 'Conakry', country: 'Guinea', lat: 9.29, lon: 13.49 },
  { city: 'Bissau', country: 'Guinea-Bissau', lat: 11.45, lon: 15.45 },
  { city: 'Georgetown', country: 'Guyana', lat: 6.5, lon: 58.12 },
  { city: 'Port-au-Prince', country: 'Haiti', lat: 18.4, lon: 72.2 },
  { city: 'Tegucigalpa', country: 'Honduras', lat: 14.05, lon: 87.14 },
  { city: 'Budapest', country: 'Hungary', lat: 47.29, lon: 19.05 },
  { city: 'Reykjavik', country: 'Iceland', lat: 64.1, lon: 21.57 },
  { city: 'New Delhi', country: 'India', lat: 28.37, lon: 77.13 },
  { city: 'Jakarta', country: 'Indonesia', lat: 6.09, lon: 10.49 },
  {
    city: 'Tehran',
    country: 'Islamic Republic of Iran',
    lat: 35.44,
    lon: 51.3,
  },
  { city: 'Baghdad', country: 'Iraq', lat: 33.2, lon: 44.3 },
  { city: 'Dublin', country: 'Ireland', lat: 53.21, lon: 6.15 },
  { city: 'Jerusalem', country: 'Israel', lat: 31.47, lon: 35.12 },
  { city: 'Rome', country: 'Italy', lat: 41.54, lon: 12.29 },
  { city: 'Kingston', country: 'Jamaica', lat: 18.0, lon: 76.5 },
  { city: 'Amman', country: 'Jordan', lat: 31.57, lon: 35.52 },
  { city: 'Astana', country: 'Kazakhstan', lat: 51.1, lon: 71.3 },
  { city: 'Nairobi', country: 'Kenya', lat: 1.17, lon: 36.48 },
  { city: 'Tarawa', country: 'Kiribati', lat: 1.3, lon: 17.0 },
  { city: 'Kuwait', country: 'Kuwait', lat: 29.3, lon: 48.0 },
  { city: 'Bishkek', country: 'Kyrgyzstan', lat: 42.54, lon: 74.46 },
  {
    city: 'Vientiane',
    country: "Lao People's Democratic Republic",
    lat: 17.58,
    lon: 10.36,
  },
  { city: 'Riga', country: 'Latvia', lat: 56.53, lon: 24.08 },
  { city: 'Beirut', country: 'Lebanon', lat: 33.53, lon: 35.31 },
  { city: 'Maseru', country: 'Lesotho', lat: 29.18, lon: 27.3 },
  { city: 'Monrovia', country: 'Liberia', lat: 6.18, lon: 10.47 },
  {
    city: 'Tripoli',
    country: 'Libyan Arab Jamahiriya',
    lat: 32.49,
    lon: 13.07,
  },
  { city: 'Vaduz', country: 'Liechtenstein', lat: 47.08, lon: 9.31 },
  { city: 'Vilnius', country: 'Lithuania', lat: 54.38, lon: 25.19 },
  { city: 'Luxembourg', country: 'Luxembourg', lat: 49.37, lon: 6.09 },
  { city: 'Macau', country: 'Macao, China', lat: 22.12, lon: 11.33 },
  { city: 'Antananarivo', country: 'Madagascar', lat: 18.55, lon: 47.31 },
  { city: 'Lilongwe', country: 'Malawi', lat: 14.0, lon: 33.48 },
  { city: 'Kuala Lumpur', country: 'Malaysia', lat: 3.09, lon: 10.41 },
  { city: 'Male', country: 'Maldives', lat: 4.0, lon: 73.28 },
  { city: 'Bamako', country: 'Mali', lat: 12.34, lon: 7.55 },
  { city: 'Valletta', country: 'Malta', lat: 35.54, lon: 14.31 },
  { city: 'Fort-de-France', country: 'Martinique', lat: 14.36, lon: 61.02 },
  { city: 'Nouakchott', country: 'Mauritania', lat: 20.1, lon: 57.3 },
  { city: 'Mamoudzou', country: 'Mayotte', lat: 12.48, lon: 45.14 },
  { city: 'Mexico', country: 'Mexico', lat: 19.2, lon: 99.1 },
  {
    city: 'Palikir',
    country: 'Federated States of Micronesia',
    lat: 6.55,
    lon: 15.09,
  },
  { city: 'Chisinau', country: 'Republic of Moldova', lat: 47.02, lon: 28.5 },
  { city: 'Maputo', country: 'Mozambique', lat: 25.58, lon: 32.32 },
  { city: 'Yangon', country: 'Myanmar', lat: 16.45, lon: 96.2 },
  { city: 'Windhoek', country: 'Namibia', lat: 22.35, lon: 17.04 },
  { city: 'Kathmandu', country: 'Nepal', lat: 27.45, lon: 85.2 },
  {
    city: 'Amsterdam',
    country: 'Netherlands',
    lat: 52.23,
    lon: 4.54,
  },
  {
    city: 'Willemstad',
    country: 'Netherlands Antilles',
    lat: 12.05,
    lon: 69.0,
  },
  { city: 'Noumea', country: 'New Caledonia', lat: 22.17, lon: 16.3 },
  { city: 'Wellington', country: 'New Zealand', lat: 41.19, lon: 17.46 },
  { city: 'Managua', country: 'Nicaragua', lat: 12.06, lon: 86.2 },
  { city: 'Niamey', country: 'Niger', lat: 13.27, lon: 2.06 },
  { city: 'Abuja', country: 'Nigeria', lat: 9.05, lon: 7.32 },
  { city: 'Kingston', country: 'Norfolk Island', lat: 45.2, lon: 16.43 },
  {
    city: 'Saipan',
    country: 'Northern Mariana Islands',
    lat: 15.12,
    lon: 14.45,
  },
  { city: 'Oslo', country: 'Norway', lat: 59.55, lon: 10.45 },
  { city: 'Masqat', country: 'Oman', lat: 23.37, lon: 58.36 },
  { city: 'Islamabad', country: 'Pakistan', lat: 33.4, lon: 73.1 },
  { city: 'Koror', country: 'Palau', lat: 7.2, lon: 13.28 },
  { city: 'Panama', country: 'Panama', lat: 9.0, lon: 79.25 },
  { city: 'Port Moresby', country: 'Papua New Guinea', lat: 9.24, lon: 14.08 },
  { city: 'Asuncion', country: 'Paraguay', lat: 25.1, lon: 57.3 },
  { city: 'Lima', country: 'Peru', lat: 12.0, lon: 77.0 },
  { city: 'Manila', country: 'Philippines', lat: 14.4, lon: 12.03 },
  { city: 'Warsaw', country: 'Poland', lat: 52.13, lon: 21.0 },
  { city: 'Lisbon', country: 'Portugal', lat: 38.42, lon: 9.1 },
  { city: 'San Juan', country: 'Puerto Rico', lat: 18.28, lon: 66.07 },
  { city: 'Doha', country: 'Qatar', lat: 25.15, lon: 51.35 },
  { city: 'Seoul', country: 'Republic of Korea', lat: 37.31, lon: 12.58 },
  { city: 'Bucuresti', country: 'Romania', lat: 44.27, lon: 26.1 },
  { city: 'Moskva', country: 'Russian Federation', lat: 55.45, lon: 37.35 },
  { city: 'Kigali', country: 'Rawanda', lat: 1.59, lon: 30.04 },
  {
    city: 'Basseterre',
    country: 'Saint Kitts and Nevis',
    lat: 17.17,
    lon: 62.43,
  },
  { city: 'Castries', country: 'Saint Lucia', lat: 14.02, lon: 60.58 },
  {
    city: 'Saint-Pierre',
    country: 'Saint Pierre and Miquelon',
    lat: 46.46,
    lon: 56.12,
  },
  {
    city: 'Kingstown',
    country: 'Saint vincent and the Grenadines',
    lat: 13.1,
    lon: 61.1,
  },
  { city: 'Apia', country: 'Samoa', lat: 13.5, lon: 17.5 },
  { city: 'San Marino', country: 'San Marino', lat: 43.55, lon: 12.3 },
  { city: 'Sao Tome', country: 'Sao Tome and Principe', lat: 0.1, lon: 6.39 },
  { city: 'Riyadh', country: 'Saudi Arabia', lat: 24.41, lon: 46.42 },
  { city: 'Dakar', country: 'Senegal', lat: 14.34, lon: 17.29 },
  { city: 'Freetown', country: 'Sierra Leone', lat: 8.3, lon: 13.17 },
  { city: 'Bratislava', country: 'Slovakia', lat: 48.1, lon: 17.07 },
  { city: 'Ljubljana', country: 'Slovenia', lat: 46.04, lon: 14.33 },
  { city: 'Honiara', country: 'Solomon Islands', lat: 9.27, lon: 15.57 },
  { city: 'Mogadishu', country: 'Somalia', lat: 2.02, lon: 45.25 },
  {
    city: 'Pretoria',
    country: 'South Africa',
    lat: 25.44,
    lon: 28.12,
  },
  { city: 'Madrid', country: 'Spain', lat: 40.25, lon: 3.45 },
  { city: 'Khartoum', country: 'Sudan', lat: 15.31, lon: 32.35 },
  { city: 'Paramaribo', country: 'Suriname', lat: 5.5, lon: 55.1 },
  { city: 'Mbabane', country: 'Swaziland', lat: 26.18, lon: 31.06 },
  { city: 'Stockholm', country: 'Sweden', lat: 59.2, lon: 18.03 },
  { city: 'Bern', country: 'Switzerland', lat: 46.57, lon: 7.28 },
  { city: 'Damascus', country: 'Syrian Arab Republic', lat: 33.3, lon: 36.18 },
  { city: 'Dushanbe', country: 'Tajikistan', lat: 38.33, lon: 68.48 },
  { city: 'Bangkok', country: 'Thailand', lat: 13.45, lon: 10.35 },
  {
    city: 'Skopje',
    country: 'The Former Yugoslav Republic of Macedonia',
    lat: 42.01,
    lon: 21.26,
  },
  { city: 'Lome', country: 'Togo', lat: 6.09, lon: 1.2 },
  { city: "Nuku'alofa", country: 'Tonga', lat: 21.1, lon: 17.0 },
  { city: 'Tunis', country: 'Tunisia', lat: 36.5, lon: 10.11 },
  { city: 'Ankara', country: 'Turkey', lat: 39.57, lon: 32.54 },
  { city: 'Ashgabat', country: 'Turkmenistan', lat: 38.0, lon: 57.5 },
  { city: 'Funafuti', country: 'Tuvalu', lat: 8.31, lon: 17.13 },
  { city: 'Kampala', country: 'Uganda', lat: 0.2, lon: 32.3 },
  { city: 'Kiev (Rus)', country: 'Ukraine', lat: 50.3, lon: 30.28 },
  {
    city: 'Abu Dhabi',
    country: 'United Arab Emirates',
    lat: 24.28,
    lon: 54.22,
  },
  {
    city: 'London',
    country: 'United Kingdom of Great Britain and Northern Ireland',
    lat: 51.36,
    lon: 0.05,
  },
  {
    city: 'Dodoma',
    country: 'United Republic of Tanzania',
    lat: 6.08,
    lon: 35.45,
  },
  {
    city: 'Washington DC',
    country: 'United States of America',
    lat: 39.91,
    lon: 77.02,
  },
  {
    city: 'Charlotte Amalie',
    country: 'United States of Virgin Islands',
    lat: 18.21,
    lon: 64.56,
  },
  { city: 'Montevideo', country: 'Uruguay', lat: 34.5, lon: 56.11 },
  { city: 'Tashkent', country: 'Uzbekistan', lat: 41.2, lon: 69.1 },
  { city: 'Port-Vila', country: 'Vanuatu', lat: 17.45, lon: 16.18 },
  { city: 'Caracas', country: 'Venezuela', lat: 10.3, lon: 66.55 },
  { city: 'Hanoi', country: 'Viet Nam', lat: 21.05, lon: 10.55 },
  { city: 'Belgrade', country: 'Yugoslavia', lat: 44.5, lon: 20.37 },
  { city: 'Lusaka', country: 'Zambia', lat: 15.28, lon: 28.16 },
  { city: 'Harare', country: 'Zimbabwe', lat: 17.43, lon: 31.02 },
];

export default capitalList;
