import { useEffect } from 'react';

function GuessingButton(props) {
  const guessedData = props.guessedData;
  const actualData = props.actualData;
  const setActualData = props.setActualData;
  const guessMade = props.guessMade;
  const setGuessMade = props.setGuessMade;
  const results = { ...props.results };
  const setResults = props.setResults;
  const points = props.points;
  const setPoints = props.setPoints;
  const lives = props.lives;
  const setLives = props.setLives;

  const makeGuess = () => {
    console.log(guessedData);
    console.log(actualData);
    // console.log(actualData.temp);
    // console.log(guessedData.temp);
    // console.log(guessedData.clouds);
    if (
      actualData.temp !== '' &&
      guessedData.temp !== '' &&
      guessedData.clouds !== ''
    ) {
      // check temperatures
      let differenceTemp = Math.abs(guessedData.temp - actualData.temp);
      switch (true) {
        case differenceTemp > 20:
          results.temp = 'fail';
          results.fail = true;
          break;
        case differenceTemp > 10:
          results.temp = 'pass';
          break;
        case differenceTemp > 5:
          results.temp = 'good';
          results.points += 5;
          break;
        case differenceTemp <= 5:
          results.temp = 'good';
          results.points += 10;
          break;
        default:
          results.temp = 'pass';
      }

      // check wind
      let guessedWindRange = Number(
        guessedData.wind.charAt(guessedData.wind.length - 1)
      );

      let actualWindRange = Number(
        actualData.wind.charAt(actualData.wind.length - 1)
      );

      switch (Math.abs(guessedWindRange - actualWindRange)) {
        case 0:
          results.wind = 'good';
          results.points += 10;
          break;
        case 1:
          results.wind = 'pass';
          break;
        case 2:
          results.wind = 'pass';
          break;
        case 3:
          results.wind = 'fail';
          results.fail = true;
          break;
      }

      // check clouds

      let guessedCloudRange = Number(
        guessedData.clouds.charAt(guessedData.clouds.length - 1)
      );

      let actualCloudRange = Number(
        actualData.clouds.charAt(actualData.clouds.length - 1)
      );

      switch (Math.abs(guessedCloudRange - actualCloudRange)) {
        case 0:
          results.clouds = 'good';
          results.points += 10;
          break;
        case 1:
          results.clouds = 'pass';
          break;
        case 3:
          results.clouds = 'fail';
          results.fail = true;
          break;
      }

      // check if points are 0
      if (results.points === 0) {
        results.fail = true;
      }

      setResults(results);
      // console.log(results);

      // update points
      if (!results.fail) {
        let newPoints = points + results.points;
        setPoints(newPoints);
      }

      // update lives
      if (results.fail || results.points === 0) {
        let newLives = lives - 1;
        setLives(newLives);
      }

      // update guessMade
      setGuessMade(true);
      // setActualData(newActualData);
      console.log(results);
    } else {
      alert('Please make a guess first!');
    }
  };

  // console.log(newActualData);

  // // update actual data
  // // setActualData(newActualData);
  // useEffect(() => {
  //   setActualData(newActualData);
  // }, [newActualData]);

  return (
    <div className='single-button'>
      <button className='button btn-subtle' onClick={() => makeGuess()}>
        Make a guess
      </button>
    </div>
  );
}

export default GuessingButton;
