// https://api.openweathermap.org/data/2.5/weather?lat={lat}&lon={lon}&appid={API key}

import openWeatherApiKey from './key';

async function getCurrentWeather(actualData, setActualData) {
  const apiKey = openWeatherApiKey;
  const baseURL = 'https://api.openweathermap.org/data/2.5/weather?';
  if (actualData.lat !== '' && actualData.lon !== '') {
    const url = `${baseURL}lat=${actualData.lat}&lon=${actualData.lon}&appid=${apiKey}`;
    let newActualData = { ...actualData };
    try {
      await fetch(url)
        .then(res => res.json())
        .then(res => {
          newActualData.temp = Math.floor(res.main.temp - 273.15);
          newActualData.temp_kelvin = res.main.temp;
          newActualData.wind_speed = res.wind.speed;
          newActualData.wind =
            res.wind.speed > 10.7
              ? 'wind-03'
              : res.wind.speed > 5.4
              ? 'wind-02'
              : res.wind.speed > 1.5
              ? 'wind-01'
              : 'wind-00';
          newActualData.wind_speed = res.wind.speed;
          newActualData.cloud_coverage = res.clouds.all;
          newActualData.clouds =
            res.clouds.all > 70
              ? 'clouds-02'
              : res.clouds.all > 20
              ? 'clouds-01'
              : 'clouds-00';
          newActualData.cloud_coverage = res.clouds.all;
          newActualData.rain = 'n/a';
          newActualData.snow = 'n/a';
          newActualData.date = 'today';
          setActualData(newActualData);
        });
    } catch (error) {
      console.log('Error: ', error);
    }
  }
}

export default getCurrentWeather;
