import React, { useEffect } from 'react';

function ContentCardContainer(props) {
  const [screensize, setScreensize] = React.useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreensize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // adjust padding according to screen size
  let padding;
  if (screensize > 414) {
    padding = props.padding;
  } else {
    padding = props.mobilePadding;
  }

  // adjust grid accorsing to screen size
  const contentCardContainerStyle = {
    display: 'grid',
    padding: `0 ${padding}`,
    marginBottom: '2rem',
    ...(screensize >= 414
      ? { gridTemplateColumns: `repeat(${props.columns}, 1fr)` }
      : { gridTemplateColumns: `repeat(1, 1fr)` }),
    gap: '1rem',
  };
  return (
    <div className='contentCardContainer' style={contentCardContainerStyle}>
      {props.content}
    </div>
  );
}

export default ContentCardContainer;
