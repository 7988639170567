import checkPattern from './checkPattern';

function computerDraw(field, setField, setPlayerTurn) {
  let gameField = [...field];
  let goodDraws = [];
  // check if computer can win
  for (let i = 0; i < gameField.length; i++) {
    if (gameField[i] === '') {
      gameField[i] = 'O';
      if (checkPattern(gameField) !== '') {
        goodDraws.push(i);
      }
      gameField[i] = '';
    }
  }
  // if no good draws were found, check if computer can prevent player from winning
  if (goodDraws.length === 0) {
    for (let i = 0; i < gameField.length; i++) {
      if (gameField[i] === '') {
        gameField[i] = 'X';
        if (checkPattern(gameField) !== '') {
          goodDraws.push(i);
        }
        gameField[i] = '';
      }
    }
  }
  // if no good draws were found, get a list of all empty fields
  if (goodDraws.length === 0) {
    for (let i = 0; i < gameField.length; i++) {
      if (gameField[i] === '') {
        goodDraws.push(i);
      }
    }
  }
  // pick random field from good draws
  const draw = goodDraws[Math.floor(Math.random() * goodDraws.length)];
  gameField[draw] = 'O';

  setField(gameField);
  setPlayerTurn(true);
}

export default computerDraw;
