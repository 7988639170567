function GameHeader(props) {
  const points = props.points;
  const lives = props.lives;

  const Lives = () => {
    let arr = [];

    for (let i = 3; i > 0; i--) {
      arr.push(
        <span
          key={i}
          className={
            i > lives
              ? 'material-symbols-outlined live inactive-live'
              : 'material-symbols-outlined live active-live'
          }
        >
          favorite
        </span>
      );
    }

    return <div className='lives'>{arr}</div>;
  };

  return (
    <div className='gameHeader'>
      <div className='points'>
        <p>
          Score: <span>{points}</span>
        </p>
      </div>
      <Lives />
    </div>
  );
}

export default GameHeader;
