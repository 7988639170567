function DrawnNumbers(props) {
  let drawn = props.drawn;
  let drawnNumbers = drawn.map((x, i) => (
    <button className='gameButton drawnNumber' id={i} key={i}>
      {x}
    </button>
  ));
  return <div className='drawnNumbers'>{drawnNumbers}</div>;
}

export default DrawnNumbers;
