function GameField(props) {
  let gameField = props.field.map((x, i) => (
    <button className='gameButton' id={i} key={i} onClick={props.clickHandler}>
      {x}
    </button>
  ));

  return <div className='gameField'>{gameField}</div>;
}

export default GameField;
