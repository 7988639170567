function GameField(props) {
  // field, clickHandler
  let field = props.field;
  let gameContent = field.map((x, i) =>
    x[0] === '_' ? (
      <button
        className='gameButton matched'
        id={i}
        key={i}
        onClick={props.clickHandler}
      >
        {x.slice(1)}
      </button>
    ) : (
      <button
        className='gameButton'
        id={i}
        key={i}
        onClick={props.clickHandler}
      >
        {x}
      </button>
    )
  );

  return <div className='gameField'>{gameContent}</div>;
}

export default GameField;
