import { Route, Routes, Link } from 'react-router-dom';
import ContactButton from './ContactButton';
import SecondaryNavigationGames from './SecondaryNavigationGames';
import React, { useState } from 'react';
import './TopNavigation.css';

function TopNavigation() {
  const [siteSection, setSiteSection] = React.useState('home');

  const changeSection = function (section) {
    setSiteSection(section);
  };

  return (
    <>
      <nav className='top-nav prim-nav'>
        <div className='site-links-container'>
          <Link
            className='top-nav-link site-link'
            onClick={() => changeSection('home')}
            to='/'
          >
            <p className='desktop-only'>Home</p>
            <span className='material-symbols-outlined mobile-only'>home</span>
          </Link>
          <Link
            className='top-nav-link site-link'
            onClick={() => changeSection('consulting')}
            to='/consulting'
          >
            <p className='desktop-only'>Business</p>
            <span className='material-symbols-outlined mobile-only'>work</span>
          </Link>
          <Link
            className='top-nav-link site-link'
            onClick={() => changeSection('games')}
            to='/games'
          >
            <p className='desktop-only'>Games</p>
            <span className='material-symbols-outlined mobile-only'>
              casino
            </span>
          </Link>
        </div>

        <Link
          className='top-nav-link contact-link'
          onClick={() =>
            (window.location.href = 'mailto:koskelanadja@gmail.com')
          }
        >
          <p className='desktop-only'>Get in touch</p>
          <span className='material-symbols-outlined mobile-only'>mail</span>
        </Link>

        {/* {siteSection === 'games' && <SecondaryNavigationGames />} */}
      </nav>
      {siteSection === 'games' && <SecondaryNavigationGames />}
    </>

    // <>
    //   <nav className='top-nav'>
    //     <div className='site-links'>
    //       <Link
    //         className='button btn-nav'
    //         onClick={() => changeSection('home')}
    //         to='/'
    //       >
    //         <p className='desktop-only'>Home</p>
    //         <i className='material-icons mobile-only'>home</i>
    //       </Link>
    //       <Link
    //         className='button btn-nav'
    //         onClick={() => changeSection('games')}
    //       >
    //         <p className='desktop-only'>Games</p>
    //         <i className='material-icons mobile-only'>casino</i>
    //       </Link>
    //       <Link
    //         className='button btn-nav'
    //         onClick={() => changeSection('weather')}
    //         to='/weather'
    //       >
    //         <p className='desktop-only'>Weather</p>
    //         <i className='material-icons mobile-only'>casino</i>
    //       </Link>
    //     </div>
    //     <ContactButton />
    //     {/* <div className='contact'>Get in touch</div> */}
    //   </nav>
    //   {siteSection === 'games' && <SecondaryNavigationGames />}
    // </>
  );
}

export default TopNavigation;
