import React, { useEffect } from 'react';
import GameField from './TTTGameField';
import WinningMessage from './WinningMessage';
import checkPattern from './checkPattern';
import Intro from './Intro';
import computerDraw from './computerDraw';
import './TicTacToe.css';

function TicTacToe() {
  const [field, setField] = React.useState(Array(9).fill(''));
  const [playerTurn, setPlayerTurn] = React.useState(true);
  const [winner, setWinner] = React.useState('');

  const playerDraw = function (e) {
    const i = e.target.id;
    let newField = [...field];
    if (newField[i] === '' && playerTurn) {
      newField[i] = 'X';
      setField(newField);
      setPlayerTurn(false);
    }
  };

  const resetField = function () {
    setField(Array(9).fill(''));
    setPlayerTurn('true');
    setWinner('');
  };

  useEffect(() => {
    let winOrNot = checkPattern(field);
    if (winOrNot !== '') {
      setWinner(winOrNot);
    } else if (!playerTurn) {
      setTimeout(() => computerDraw(field, setField, setPlayerTurn), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  return (
    <main className='tictactoe'>
      <Intro />
      <div className='gameArea'>
        <GameField field={field} clickHandler={playerDraw} />
        {winner !== '' && <WinningMessage winner={winner} />}

        <button className='button btn-highlight reset-btn' onClick={resetField}>
          Reset
        </button>
      </div>
    </main>
  );
}

export default TicTacToe;
