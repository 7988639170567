import { Route, Routes, Link } from 'react-router-dom';
import './TopNavigation.css';

function SecondaryNavigationGames() {
  return (
    <nav className='top-nav sec-nav'>
      <Link className='top-nav-link site-link' to='/tictactoe'>
        <p className='desktop-only'>TicTacToe</p>
        <span className='material-icons mobile-only'>grid_3x3</span>
      </Link>
      <Link className='top-nav-link site-link' to='/bingo'>
        <p className='desktop-only'>Bingo</p>
        <span className='material-symbols-outlined mobile-only'>ifl</span>
      </Link>
      <Link className='top-nav-link site-link' to='/weather'>
        <p className='desktop-only'>Weather</p>
        <span className='material-symbols-outlined mobile-only'>
          partly_cloudy_day
        </span>
      </Link>
      {/* <Link className='top-nav-link site-link' to='/bigmoney'>
        <p className='desktop-only'>Big Money</p>
        <span className='material-symbols-outlined mobile-only'>payments</span>
      </Link> */}
    </nav>
  );
}

export default SecondaryNavigationGames;
