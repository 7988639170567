function Intro() {
  return (
    <div className='intro'>
      <h1>Big Money</h1>
      <p>
        Nam commodo suscipit quam. Donec quam felis, ultricies nec, pellentesque
        eu, pretium quis, sem. Etiam ultricies nisi vel augue. Praesent nonummy
        mi in odio. Aenean tellus metus, bibendum sed, posuere ac, mattis non,
        nunc. Mauris sollicitudin fermentum libero. Suspendisse nisl elit,
        rhoncus eget, elementum ac, condimentum eget, diam. Nam quam nunc,
        blandit vel, luctus pulvinar, hendrerit id, lorem. Fusce vulputate
        eleifend sapien. Vestibulum dapibus nunc ac augue.
      </p>
    </div>
  );
}

export default Intro;
